// import external modules
import React from "react";
import { Card, CardBody, CardHeader, CardImg, Col, Row } from "reactstrap";

import ImgSrc from "../../assets/img/logos/logo-codigo-black.png";

const Maintainance = props => {
   return (
      <div className="container-fluid gradient-indigo-dark-blue">
         <Row className="full-height-vh">
            <Col xs="12" className="d-flex align-items-center justify-content-center">
               <Card className="border-grey border-lighten-3 px-1 py-1 box-shadow-3">                  
                  <CardHeader>
                     <CardImg
                        alt="element 06"
                        // className="img-fluid mx-auto d-block pt-2 mb-1 m-auto width-100"
                        className="img-fluid mx-auto d-block pt-2 mb-1 m-auto width-200"
                        // className={"img-fluid"}
                        src={ImgSrc}
                     />
                  </CardHeader>
                  <CardBody className="text-center">
                     <h3>O sistema está em manutenção</h3>
                     <p>
                        Estamos fazendo alguns ajustes
                        <br /> Retorne novamente em breve.
                     </p>
                     <div className="mt-2">
                        <i className="fa fa-cog spinner font-large-2" />
                     </div>

                     <hr />
                     <p className="text-center darken-1 mt-1">
                           © 2023{" "}
                           by <i className="ft-heart font-small-1" />            
                           <a href="https://www.instagram.com/codigosistemas/" rel="noopener noreferrer" target="_blank">
                              {" "}
                              {/* <span className="marca" style={{color: "#706DD8", fontWeight: "bold"}}>{`<\\`}</span>
                              <span className="marca" style={{color: "#158FD1", fontWeight: "bold"}}>código</span>
                              <span className="marca" style={{color: "#706DD8", fontWeight: "bold"}}>{`>`}</span> */}
                              @codigosistemas
                           </a>
                        </p>
                     {/* <p className="socialIcon card-text text-center pt-2 pb-2">
                        
                     </p> */}
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </div>
   );
};

export default Maintainance;
