// import external modules
import React, { Suspense, lazy } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";

// import internal(own) modules
import ReduxToastr from 'react-redux-toastr';
import { store } from "./redux/storeConfig/store";
import registerServiceWorker from "./registerServiceWorker";

import "font-awesome/css/font-awesome.min.css";

import Spinner from "./components/spinner/spinner";
import "./index.scss";
import Maintainance from "./views/pages/maintainance";

const LazyApp = lazy(() => import("./app/app"));
const LazyMaintainance = lazy(() => import("./views/pages/maintainance"));


const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(<Provider store={store}>
   <Suspense fallback={<Spinner />}>
      { process.env.REACT_APP_MAINTENANCE == "1" ? <Maintainance/> : <LazyApp />}
     <ReduxToastr
         timeOut={4000}
         newestOnTop={false}
         preventDuplicates
         position="top-left"
         transitionIn="fadeIn"
         transitionOut="fadeOut"
         progressBar
         closeOnToastrClick/>
   </Suspense>
</Provider>);

// createRoot (
//    <Provider store={store}>
//       <Suspense fallback={<Spinner />}>
//          { process.env.REACT_APP_MAINTENANCE == "1" ? <Maintainance/> : <LazyApp />}
//         <ReduxToastr
//             timeOut={4000}
//             newestOnTop={false}
//             preventDuplicates
//             position="top-left"
//             transitionIn="fadeIn"
//             transitionOut="fadeOut"
//             progressBar
//             closeOnToastrClick/>
//       </Suspense>
//    </Provider>,
//    document.getElementById("root")
// );
registerServiceWorker();
